<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form ref="payment_history_form">
              <b-row>
                  <b-col md="3">
                      <b-card-text>
                          <span>{{  $t("Flat")  }} </span>
                      </b-card-text>
                      <validation-provider #default="{ errors }" :name="$t('Class')" rules="required">
                          <select v-model.number="version_id" class="form-control" name="version_id" id="version_id"
                          :state="errors.length > 0 ? false : null">
                          <option :value="null">Select Flat</option>
                          <option v-for="(ver, key) in versions" :key="key" :value="ver.id">
                              {{  ver.name  }}
                          </option>
                          </select>
                          <small class="text-danger">{{  errors[0]  }}</small>
                      </validation-provider>
                  </b-col>

                  <b-col md="3">
                      <b-card-text>
                          <span>Select Student </span>
                      </b-card-text>
                      <validation-provider #default="{ errors }" name="wing" rules="required">
                          <select name="student_id" v-model="student_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="form-control">
                              <option :value="null">Select One</option>
                              <option v-for="(std, key) in studentList" :value="std.id" :key="key">
                                  {{ std.nick_name + '-'+ std.userable.name }}
                              </option>
                          </select>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-col>

                  <b-col md="3">
                      <b-card-text>
                          <span>Select Report Type </span>
                      </b-card-text>
                      <validation-provider #default="{ errors }" name="report_type_id" rules="required">
                          <select id="report_type_id" v-model="report_type_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                              <option :value="null">Select One</option>
                              <option :value="1" :key="key">Monthly</option>
                              <option :value="2" :key="key">Yearly</option>
                              <option :value="3" :key="key">By Date Range</option>
                          </select>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-col>

                  <b-col md="3" v-if="report_type_id === 1 || report_type_id === 2">
                      <b-card-text>
                          <span>Select Year</span>
                      </b-card-text>
                      <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
                          <select name="academic_year_id" id="academic_year_id" v-model="year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="form-control">
                              <option :value="null">Select One</option>
                              <option v-for="(ayear, key) in active_academic_years" :value="ayear.id" :key="key">
                                  {{ ayear.year }}
                              </option>
                          </select>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-col>

                  <b-col md="3" v-if="report_type_id === 1">
                      <b-card-text>
                          <span>Select Month </span>
                      </b-card-text>
                      <validation-provider #default="{ errors }" name="month_id" rules="required">
                          <select id="month_id" v-model="month_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="form-control">
                              <option :value="null">Select One</option>
                              <option :value="1" :key="key">January</option>
                              <option :value="2" :key="key">February</option>
                              <option :value="3" :key="key">March</option>

                              <option :value="4" :key="key">April</option>
                              <option :value="5" :key="key">May</option>
                              <option :value="6" :key="key">June</option>

                              <option :value="7" :key="key">July</option>
                              <option :value="8" :key="key">August</option>
                              <option :value="9" :key="key">September</option>

                              <option :value="10" :key="key">Otcober</option>
                              <option :value="11" :key="key">November</option>
                              <option :value="12" :key="key">December</option>
                          </select>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-col>
                  <b-col md="3" v-if="report_type_id === 3">
                      <b-card-text>
                          <span>{{ $t("Start Date") }} </span>
                      </b-card-text>
                      <validation-provider #default="{ errors }" :name="$t('report start date')" rules="required">
                          <b-form-datepicker name="rpt_start_date" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit' }"
                              v-model="rpt_start_date" :placeholder="$t('Select start date')" class="form-control">
                          </b-form-datepicker>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-col>

                  <b-col md="3" v-if="report_type_id === 3">
                      <b-card-text>
                          <span>{{ $t("End Date") }} </span>
                      </b-card-text>
                      <validation-provider #default="{ errors }" :name="$t('report end date')" rules="required">
                          <b-form-datepicker name="rpt_end_date" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit' }"
                              v-model="rpt_end_date" :placeholder="$t('select end date')" class="form-control">
                          </b-form-datepicker>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-col>

                  <b-col md="12" class="mt-2">
                      <b-button variant="primary" type="submit" @click.prevent="loadPaymentHistory">
                          Populate
                      </b-button>
                  </b-col>
              </b-row>
          </b-form>

    </validation-observer>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table 
    style="white-space: nowrap;
    text-align: center;
    vertical-align : middle;" 
    :columns="columns"
    :rows="payment_history" 
    :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field === 'created_at'">
          {{ moment(props.row.payment_date).format('YYYY-MM-DD') }}
        </span>
        <span v-else-if="props.column.field === 'month_year'">
          {{ getMonthName(props.row.month_year, props.row.academic_year) }}
        </span>

        <span v-else-if="props.column.field === 'action'">
          <b-button variant="secondary" @click="viewDetails(props.row.payment_type_list)"
            style="padding-left:1cm; float: left; vertical-align: middle;" title="View Payment Type Details">
            <feather-icon class="text-body text-center" icon="EyeIcon" size="15" />
          </b-button>

          <b-button variant="success" style="float: right; vertical-align: middle;"
            @click="downloadPaymentReceipt(props.row.invoice_id, props.row.student_id, props.row.payment_head_name, getMonthName(props.row.month_year, props.row.academic_year), props.row.academic_year_id)">
            <feather-icon icon="DownloadIcon" class="mr-50" />
          </b-button>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select v-model="pageLength" :options="['10', '15', '20']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal ref="payment-view" hide-footer size="sm" :title="$t('Payment Details')">
      <table class="table table-striped table-bordered" style="border-collapse: collapse; border-spacing: 0 10px; ">
        <thead>
          <tr>
            <th>SL#</th>
            <th>Payment Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(payment_info, i) in payment_detail_array" :key="i">
            <th scope="row">{{ (i + 1) }}</th>
            <td>{{ payment_info.payment_type_name }}</td>
            <td>{{ payment_info.payment_type_amount }}</td>
          </tr>
        </tbody>

      </table>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BForm,
  BCardText,
  BFormDatepicker,
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton
} from 'bootstrap-vue';

import { VueGoodTable } from 'vue-good-table';
import store from '@/store/index';
import Ripple from 'vue-ripple-directive';
import apiCall from "@/libs/axios";
import moment from "moment";
import download from "downloadjs";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
export default {
  name: 'reportCollection',
  components: {
    BCardCode,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BFormDatepicker,
    BForm,
    BCardText
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      classes_id: null,
      wing_id: null,
      rpt_start_date: "",
      rpt_end_date: "",
      required,
      integer,
      classes: [],
      wings: [],
      report_type_id: null,
      month_id: null,
      year_id: null,
      version_id: null,
      student_id: null,
      payment_history: [],
      payment_detail_array: [],
      moment,

      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Fee Head',
          field: 'payment_head_name',
        },
        {
          label: 'Total Paid',
          field: 'total_amount',
        },
        {
          label: 'Payment for',
          field: 'month_year',
        },
        {
          label: 'Paid At',
          field: 'created_at',
        },
        {
          label: "Receipt",
          field: "action",
        }
      ],
      searchTerm: '',
      status: {
        0: 'Pending',
        1: 'Approved',
        2: 'Declined'
      },
    }
  },
  methods: {
    getMonthName(month_id, academic_year) {
      var month_name = '';
      if (parseInt(month_id) === 1) {
        month_name = 'January';
      }
      else if (parseInt(month_id) === 2) {
        month_name = 'February';
      }
      else if (parseInt(month_id) === 3) {
        month_name = 'March';
      }
      else if (parseInt(month_id) === 4) {
        month_name = 'April';
      }
      else if (parseInt(month_id) === 5) {
        month_name = 'May';
      }
      else if (parseInt(month_id) === 6) {
        month_name = 'June';
      }
      else if (parseInt(month_id) === 7) {
        month_name = 'July';
      }
      else if (parseInt(month_id) === 8) {
        month_name = 'August';
      }
      else if (parseInt(month_id) === 9) {
        month_name = 'September';
      }
      else if (parseInt(month_id) === 10) {
        month_name = 'October';
      }
      else if (parseInt(month_id) === 11) {
        month_name = 'November';
      }
      else if (parseInt(month_id) === 12) {
        month_name = 'December';
      }

      return month_name + ', '+ academic_year;
    },
    viewDetails(payment_detail_list) {
      this.payment_detail_array = payment_detail_list;
      this.$refs['payment-view'].show();
    },
    loadPaymentHistory() {
      let data = new FormData(this.$refs.payment_history_form);
      data.append('student_id', this.student_id);
      
      apiCall
          .post("/student/personal/payment/historybyid", data)
          .then((response) => {
              this.payment_history = response.data;
          })
          .catch((error) => {
              this.$toaster.error(error.response.data.message);
              this.payment_history = [];
          });
  },
    async getMaterials() {
      await apiCall.get('/student/personal/payment/history').then((response) => {
        this.payment_history = response.data;
      }).catch(() => {
        this.payment_history = [];
      })
    },
    downloadPaymentReceipt(invoice_id, student_id, report_title, month_name, academic_year_id) {
      let data = new FormData();
      data.append("invoice_id", invoice_id);
      data.append("student_id", student_id);
      data.append("report_title", report_title);
      data.append("month_name", month_name);
      data.append("academic_year_id", academic_year_id);
      apiCall
        .post("/student/download/receipt/byinvoiceid", data, {
          responseType: "blob",
        })
        .then((response) => {
          const content = response.headers["content-type"];
          download(response.data, "student_payment_receipt.pdf", content);
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
        });
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters([
          "active_academic_years",
          'versions',
          "students"
      ]),

      studentList() {
        const students = this.$store.getters.students.filter((item) => {
        if (
          item.version_id === parseInt(this.version_id)
        )
        return item;
    });
    return students;
  }

  },
  created() {
   // this.getMaterials();
    if (this.students.length < 1) this.$store.dispatch("GET_ALL_STUDENT");
      if (this.versions.length < 1) this.$store.dispatch("GET_ALL_VERSION");
      if (this.active_academic_years.length < 1) this.$store.dispatch('GET_ACTIVE_ACADEMIC_YEAR');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "@core/scss/vue/libs/vue-select.scss";
</style>